'use strict';

angular.module('managerApp').config( ($routeProvider) => {
    $routeProvider.when('/supports', {
        templateUrl: 'app/supports/supports.html',
        controller: 'SupportsController',
        authenticate: 'user',
    },$routeProvider.when('/supports/partners', {
        templateUrl: 'app/supports/partners/supports.partners.html',
        controller: 'SupportPartnersController',
        authenticate: 'user',
    })
)
});
